import React from "react";
import { Tabs, Tab, TabBody } from "react95";
import "./styles.scss";

export default function Screenshots() {

    const pageNumbers7a = [...Array(20).keys()];
    pageNumbers7a.shift();

    const pageNumbers7b = [...Array(24).keys()];
    pageNumbers7b.shift();

    const [state, setState] = React.useState({
        activeTab: 0
    });

    const handleChange = (value) => {
        setState({ activeTab: value });
    };
    
    const { activeTab } = state;

    return (
        <section className="projectscreenshotsWindow">
            <div>
                <Tabs value={activeTab} onChange={handleChange}>
                    <Tab value={0}>Full Stack</Tab>
                    <Tab value={1}>Other</Tab>
                </Tabs>
                <TabBody style={{ height: "70vh" }}>
                {activeTab === 0 && (
                    <div className="scrollable projectscreenshotsWindow__details">
                    <div className="projectscreenshotsWindow__item">
                        <img src={require("../../assets/media/FrontEnd Running.png")} alt="" style={{ width: "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2rem" }} />
                    </div>
                    <p>The Front End running</p>

                    <div className="projectscreenshotsWindow__item">
                        <img src={require("../../assets/media/API Deployed.png")} alt="" style={{ width: "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2rem" }} />
                    </div>
                    <p>API is deployed</p>
                    <div className="projectscreenshotsWindow__item">
                        <img src={require("../../assets/media/API CORS and Resources.png")} alt="" style={{ width: "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2rem" }} />
                    </div>
                    <p>API CORS and resources</p>

                    <div className="projectscreenshotsWindow__item">
                        <img src={require("../../assets/media/API URL Successfully Running.png")} alt="" style={{ width: "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2rem" }} />
                    </div>
                    <p>The API URL is successfully running</p>
                    
                    <div className="projectscreenshotsWindow__item">
                        <img src={require("../../assets/media/Docker2.png")} alt="" style={{ width: "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2rem" }} />
                    </div>
                    <div className="projectscreenshotsWindow__item">
                        <img src={require("../../assets/media/Docker.png")} alt="" style={{ width: "75%", marginLeft: "auto", marginRight: "auto", marginTop: "rem" }} />
                    </div>
                    </div>
                )}

                {activeTab === 1 && (
                    <div className="scrollable projectscreenshotsWindow__details">
                    <div className="eprojectscreenshotsWindow__item">
                        <img 
                            src={require("../../assets/media/3DRender.png")} 
                            alt="" 
                            style={{ width: "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2rem"}} 
                        />
                    </div>
                    </div>
                )}
                </TabBody>
            </div>
            </section>
    );
}