import React from "react";
import { Anchor } from "react95";

import "./styles.scss";

export default function Projects() {

    return (
        <section className="projectsWindow">
            <div className="scrollable projectsWindow__body">
                <h1>Projects</h1>
                <div className="projectsWindow__bodyInner">
                    <Anchor className="projectTitle" href="https://github.com/dalsoto/CS-360" target="_blank">
                        <h2>Event Tracker</h2>
                    </Anchor>
                    <h3>Feb 2023: Java</h3>
                    <p>
                    The purpse for the application of the Event Tracker was to be able to have a list of the events for previous and future events into a database. The user should be able to add and delete from this database. Being able to log into your own account is also required.
                    </p>
                    <img 
                        src={require("../../assets/media/TrackerApp1.png")} 
                        alt=""
                    />
                    <img 
                        src={require("../../assets/media/TrackerApp2.png")} 
                        alt=""
                    />
                    <img 
                        src={require("../../assets/media/TrackerApp3.png")} 
                        alt=""
                    />

                    <Anchor className="projectTitle" href="https://github.com/dalsoto/tracking.io" target="_blank">
                        <h2>Spotify Tracking Application</h2>
                    </Anchor>
                    <h3>Nov 2023: Python, CSS, HTML, Spotify API</h3>
                    <p>
                    This is still a work in progress.  As of right now it only tracking your recently played songs from your Spotify acount.  Below are some screenshots.
                    </p>
                    <img
                        src={require("../../assets/media/Spotify1.png")} 
                        alt=""
                    />
                    <img
                        src={require("../../assets/media/Spotify2.png")}
                        alt=""
                    />
                    <img
                        src={require("../../assets/media/Spotify3.png")}
                        alt=""
                    />
                </div>
            </div>
        </section>
    );
}