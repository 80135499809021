import React from "react";

import "./styles.scss";

export default function About() {

    return (
        <section className="aboutWindow">
            <div className="scrollable aboutWindow__body">
                <h1>Welcome!</h1>
                <div className="aboutWindow__bodyInner">
                    <img 
                        src={require("../../assets/media/profile-picture.jpg")}
                        alt="Headshot picture"
                        className="profile-picture"
                    />
                    <div className="description">
                        <p>
                        My name is Dalton Soto and I graduated from Southern New Hampshire University in September of 2023 with a B.S. in Computer Science.
                        </p>
                        <br />
                        <p>
                        I'm a new graduate currently based in Wisconsin (Willing to relocate) and seeking entry-level software roles as an aspiring developer. I have a passion for software development and a record of academic and personal projects that gave me experience with modern languages, frameworks and tools.
                        </p>
                        <br />
                        <p>
                        My education at SNHU provided a strong foundation in computer programming and problem-solving. I challenged myself during school projects and side projects, building marketable skills that will help my career. Please feel free to click on the project icons to explore my work and experiences.
                        </p>
                    </div>
                </div>

            </div>
        </section>
    );
}